<template>
    <div class="dg-pop-layer on pop-wd-mid st-meeting-attend" id="popMeetingRoomPasswordBox">
      <div class="dg-pop-hd-st2-box st-small">
        <div class="pop-h1">해당 회의실에 들어가기 위해서는 비밀번호가 필요합니다.</div>
        <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopup">닫기</a></div>
      </div>
      <div class="pop-cont-box ">
        <div class="pop-mem-change-form st-wide">
          <div class="row-box">
          <span class="input-box">
              <input class="input-st1 h40 al-center" placeholder="비밀번호를 입력해주세요." type="password" v-model="pswd">
          </span>
          </div>
        </div>
        <div class="board-btn-box wd150 al-center mb-none"><a href="javascript:;" @click="onClickEnter" :style="styleObject" >입장하기</a></div>
      </div>
    </div>
</template>

<script>
export default {
  name: "EnterVideoPopup",
  data(){
    return {
      pswd: '',
      confirm: false,
      styleObject:{
        'background-color':'#666666',
        cursor:'default'
      }
    }
  },
  props:{
    roomInfo:{
      type:Object
    }
  },
  watch:{
    pswd(){
      if (this.pswd != null && this.pswd != '') {
        this.styleObject = {
          'background-color': '',
          cursor: 'pointer'
        }
      }else{
        this.styleObject = {
          'background-color':'#666666',
          cursor:'default'
        }
      }
    }
  },
  methods:{
    closePopup(){
      this.$emit('click:bgDimClose',false)
    },
    onClickEnter(){
      if(this.roomInfo.roomPassword == this.pswd){
        let roomUrl = `${process.env.VUE_APP_HYPER_MEETING}/meeting/${this.roomInfo.roomId}?userId=${this.$store.state.user.userid}&userName=${this.$store.state.user.username}&password=${this.roomInfo.roomPassword}`;
        window.open(roomUrl, '_new');
        this.closePopup();
      }else{
         alert('비밀번호가 틀렸습니다.')
      }
    }
  }
}
</script>

<style scoped>

</style>