<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-mypage" >

    <section class="content-wrap st-center">
      <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
        <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
        <div class="sub-tab-mb-title">
          <a href="javascript:;" class="on" @click="onClickTabMobile()" >화상 회의 <i class="arrow"></i></a> 
        </div>
        <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
          <a href="javascript:;"><router-link to="/my/myInfo">회원정보</router-link> </a>
          <a href="javascript:;"><router-link to="/my/meetList">모임 내역</router-link></a>
          <a href="javascript:;"><router-link to="/my/videoConference">화상 회의</router-link></a>
          <a href="javascript:;"><router-link to="/my/board">내 게시글</router-link></a>
          <a href="javascript:;"><router-link to="/my/message">쪽지함</router-link></a>
          <a href="javascript:;"><router-link to="/my/inquiry">1:1 문의</router-link></a>
          <a href="javascript:;" @click="logout">로그아웃</a>
        </div>
      </div>

      <CreateVideoPopup v-if="popupCreate" @click:bgDimClose="onClickCreatePopup"></CreateVideoPopup>
      <EnterVideoPopup v-else-if="popupEnter" @click:bgDimClose="onClickEnterPopup" :roomInfo="roomInfo"></EnterVideoPopup>

      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">

          <div class="mypage-wrap">
            <div class="aside-box">
              <div class="people-info-box">
                <div class="img-box">
                  <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
                  <img class="member-list-box" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
                </div>
                <div class="name-box">
                  <div class="name"><em>{{ userDetail.name }}</em><span>님</span></div>
                  <div class="cate">{{ userDetailDepartment }} / {{ memberClub(userDetail.clubGroup) }}</div>
                </div>
                <div class="btn-box">
                  <router-link to="/my/message">쪽지함</router-link>
                  <router-link to="/my/inquiry">1:1 문의</router-link>
                </div>
              </div>
              <div class="menu-box">
                <ul>
                  <li><router-link to="/my/myInfo">회원정보</router-link></li>
                  <li><router-link to="/my/inquiry">활동 내역</router-link>
                    <ul>
                      <li><router-link to="/my/inquiry">1:1 문의</router-link></li>
                      <li><router-link to="/my/board">내 게시글</router-link></li>
                      <li><router-link to="/my/message">쪽지함</router-link></li>
                    </ul>
                  </li>
                  <li><router-link to="/my/meetList">모임 내역</router-link></li>
                  <li class="on"><a href="javascript:;" @click="onClickMenu">화상 회의</a>
                    <ul>
                      <li class="on"><a href="javascript:;" @click="onClickMenu">전체 회의</a></li>
                      <li><router-link to="/my/myConference">내가 개설한 회의</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="main-body-box">
              <div class="my-tit-box">전체회의</div>
              <div class="board-search-wrap st3">
                <div class="tit-box"><router-link to="/my/myConference" class="btn-mod-2 wd-auto">내가 개설한 회의</router-link></div>
                <div class="search-box pc-only">
                  <select v-model="param.searchColumn" class="input-st1 st-selectbox wd150" style="height: 40px;line-height: 38px;">
                    <option value="roomName">회의실 이름</option>
                    <option value="createdByName">개설자</option>
                  </select>
                  <input type="text" v-model="param.searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd280 dg-ml-5" style="height: 40px;line-height: 38px;" @keydown="onKeydown">
                  <a href="javascript:;" @click="onClickSearch" style="height: 40px;line-height: 38px;" class="btn-s dg-ml-5">검색</a>
                </div>
              </div>
              <div class="tb-01 st2 st-over mb50">
                <table>
                  <colgroup><col style="width:70px"><col style="width:150px"><col style="width:*"><col style="width:120px"><col style="width:100px"><col style="width:110px"></colgroup>
                  <thead>
                  <tr>
                    <th>번호</th>
                    <th>회의실 아이디</th>
                    <th>제목</th>
                    <th>개설자</th>
                    <th>상태</th>
                    <th>공개여부</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(content,idx) in contents" :key="content.roomId" class="mb-one-row">
                    <td class="al-center pc-only">{{ pagination.totalElements - ((pagination.currentPage-1) * pagination.pageSize) - idx }}</td>
                    <td class="al-center">
                      <a href="javascript:;" @click="onClickTitle(content)" class="ellipsis_inline" style="max-width: 500px">{{ content.roomId }}</a>  
                    </td>
                    <td class=" ">
                      <a href="javascript:;" @click="onClickTitle(content)" class="ellipsis_inline" style="max-width: 500px">{{ content.roomName }}</a>
                    </td>
                    <td class="al-center">{{ content.createdByName }}</td>
                    <td class="al-center">{{ content.roomState=='STATE_OPENED'?'회의중':'종료' }}</td>
                    <td class="al-center">{{ content.hasPassword?'비공개':'공개' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="board-btn-box">
                <a href="javascript:;" @click="onClickCreatePopup(true)">회의실 생성</a>
              </div>
              <!-- class : pagination (S) -->
              <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
              <!-- class : pagination (E) -->
            </div>
          </div>

        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import moment from "moment";
import userApi from "@/api/UserApi";
import hyperMeetingApi from "@/api/HyperMeetingApi";
import DgPagination from '@/views/component/DgPagination';
import EnterVideoPopup from "@/views/mypage/popup/enterVideo_popup";
import CreateVideoPopup from "@/views/mypage/popup/createVideo_popup";

export default {
  name: "video_list",
  components:{
    CreateVideoPopup,
    EnterVideoPopup,
    DgPagination
  },
  created(){
    if(this.userId == '') {
      alert('로그인 후 이용하실 수 있습니다.')
      this.$router.push(`/login`)
    }else{
      this.getUserDetail();
      this.onClickSearch();
    }
  },
  data(){
    return {
      userId: this.$store.state.user.userid,
      userDetail: {},
      userDetailDepartment: '',

      contents: [],
      pagination:{
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },
      param: {
        searchColumn: 'roomName',
        searchKeyword: ''
      },

      popupCreate:false,
      popupEnter:false,
      roomLeaderCheck:null,
      hyperUserName : null,
      tablistOpen : false,
    }
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    isOpen(e){
      if(!e){
        this.popupCreate=false
        this.popupEnter=false
      }
    }
  },
  methods: {
    onClickDelete(val){
        if(confirm('회의실 개설 내역을 삭제하시겠습니까?')) {
          hyperMeetingApi.deleteRoom(val).then(()=>{
              this.pagination.currentPage = 1;
              this.onClickSearch();
            })
        }
    },
    getUserDetail(){
      userApi.get(this.userId).then((data) =>{
        this.userDetail = data
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    memberClub(val){
      if(val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }else{
        return '';
      }
    },
    onClickSearch(){
      let date = new Date()
      let params = {...this.param, ...{
          page: this.pagination.currentPage,
          size: this.pagination.pageSize,
          fromDate:moment(new Date(date.setDate(date.getDate() - 7))).format('YYYY-MM-DD'),
          toDate:moment(new Date()).format('YYYY-MM-DD')
        }};
      hyperMeetingApi.list(params).then((data)=>{
        this.contents = data.content;

        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
      })
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.onClickSearch();
    },
    async onClickTitle(item){
      let today = new Date();
      this.hyperUserName = this.$store.state.user.username;
      // console.log("item.createdBy : "+ item.createdBy)
      // console.log("this.$store.state.user.userid : "+ this.$store.state.user.userid)
      // console.log("hyperUserName : "+this.hyperUserName)
      // console.log(this.$store.state.user.userid == item.createdBy)
      if(item.roomState == 'STATE_OPENED') {
        let roomUrl = `https://hypermeeting.biz/meeting/${item.roomId}?userId=${this.$store.state.user.userid}&userName=`+this.hyperUserName
        // let roomUrl = `https://hypermeeting.biz/meeting/${item.roomId}?userId=${this.$store.state.user.userid}&userName=${this.$store.state.user.username}`
        await hyperMeetingApi.exist(item.roomId).then(data=>{
          this.roomLeaderCheck = data.data.leaderJoined;
        })
        if (item.createdBy === this.$store.state.user.userid && this.roomLeaderCheck===false) {
          roomUrl += `&leaderKey=${item.leaderKey}`
          if (item.hasPassword) {
            roomUrl += `&password=${item.roomPassword}`
          }
          window.open(roomUrl, '_new');
        } else {
          if (item.hasPassword) {
            this.onClickEnterPopup(true);
            this.roomInfo = item;
          }else {
            if(item.createdBy == this.$store.state.user.userid){
              this.hyperUserName = this.hyperUserName+'PC:'+today.getMinutes()+':'+today.getSeconds();
              roomUrl = `https://hypermeeting.biz/meeting/${item.roomId}?userId=${this.$store.state.user.userid}&userName=`+this.hyperUserName
            }
            window.open(roomUrl, '_new');
          }
        }
      }else{
        alert('종료된 회의입니다.')
      }

    },
    onKeydown(e){
      this.pagination= {
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages: 0,
        totalElements: 0
      }

      if(e.keyCode===13){
        this.onClickSearch();
      }
    },
    onClickMenu(){
      this.contents= [],
      this.pagination= {
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages: 0,
        totalElements: 0
      },

      this.searchColumn='boardName',
      this.searchKeyword=''

      this.onClickSearch();
    },

    onClickCreatePopup(e){
      this.popupCreate = e
      this.popupEnter = !e
      this.$emit('click:bgDim', this.popupCreate)
    },
    onClickEnterPopup(e){
      this.popupCreate = !e
      this.popupEnter = e
      this.$emit('click:bgDim', this.popupEnter)
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    },
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
  },
}
</script>

<style scoped>

</style>