import axios from 'axios';
import {clarify} from "@/utils/clear";

class HypermeetingApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
        this.hyperMeetingEndpoint = process.env.VUE_APP_HYPER_MEETING_API;
    }
    // this.endpoint = "http://localhost:18080/api"
    // process.env.VUE_APP_BASE_API;
    // process.env.VUE_APP_HYPER_MEETING_API;

    list(body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/hypermeeting`, {params}).then(response=>response.data);
    }


    get(roomId) {
        return axios.get(`${this.endpoint}/hypermeeting/${roomId}`, {}).then(response=>response.data);
    }

    save(room) {
        return axios.post(`${this.endpoint}/hypermeeting/save`, room).then(response=>response);
    }

    deleteRoom(roomId) {
        return axios.delete(`${this.endpoint}/hypermeeting/delete/${roomId}`, {}).then(response=>response.data);
    }

    invite(roomId, data) {
        return axios.post(`${this.endpoint}/hypermeeting/${roomId}/invite`, data).then(response=>response);
    }


    create(room) {
        return axios.post(`${this.hyperMeetingEndpoint}/hmRoom/room/capacity`, room).then(response=>response);
    }

    exist(roomId) {
        return axios.get(`${this.hyperMeetingEndpoint}/hmRoom/room/${roomId}`, {}).then(response=>response);
    }

    registry(roomId, leaderKey) {
        return axios.post(`${this.endpoint}/util/hyper-meeting/registry/${roomId}/${leaderKey}`, {}).then(response=>response.data);
    }

    delete() {
        return axios.delete(`${this.endpoint}/util/hyper-meeting/delete`, {}).then(response=>response.data);
    }

}


export default new HypermeetingApi();
