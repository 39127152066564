<template>
  <div id="contents">
    <div class="dg-pop-layer on pop-wd-mid st-meeting-attend" id="popMeetingRoomRegBox">
      <div class="dg-pop-hd-st2-box st-line">
        <div class="pop-h1">회의실 개설</div>
        <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopup">닫기</a></div>
      </div>
      <div class="pop-cont-box ">
        <form action="">
          <div class="pop-mem-change-form">
            <div class="row-box">
              <span class="tit-box">회의실 이름</span>
              <span class="input-box">
                  <input v-model="roomName" class="input-st1 h40" placeholder="회의실 이름을 작성해주세요." maxlength="40">
              </span>
            </div>
            <div class="row-box">
              <span class="tit-box">비밀번호</span>
              <span class="input-box st-pw-chk">
                  <input type="checkbox" class="chk-st1" id="chk0101" v-model="hasPassword"><label for="chk0101">회의 비공개 설정</label>
                  <input class="input-st1 h40" placeholder="비밀번호" type="password" v-model="password" :disabled="!hasPassword" :style="inputDisable">
              </span>
            </div>
          </div>
        </form>
        <div class="board-btn-box wd150 al-center mb-none mb-pd-1rem"><a href="javascript:;" @click="onClickCreate">개설</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import hypermeetingApi from "@/api/HyperMeetingApi";

export default {
  name: "CreateVideoPopup",
  data() {
    return {
      roomName: '',
      password: '',
      hasPassword: false,
      inputDisable:{'background-color': '#dfdfdf'}
    }
  },
  created() {
  },
  watch: {
    hasPassword() {
      if (this.hasPassword) {
        this.inputDisable = {'background-color': ''}
      } else {
        this.inputDisable = {'background-color': '#dfdfdf'}
      }
    }
  },
  methods: {
    closePopup() {
      this.$emit('click:bgDimClose', false)
    },
    onClickCreate() {
      if (this.roomName == '') {
        alert('회의실 이름을 입력해주세요.')
      } else if (this.hasPassword && this.password == '') {
        alert('비밀번호를 입력해주세요.')
      } else {
        let room = {}
        room.roomName = this.roomName;
        room.hasPassword = this.hasPassword;
        room.password = this.roomPassword;

        hypermeetingApi.save(room).then(data => {
          console.log(data);
          confirm('회의실 개설이 완료되었습니다. \n회원님들을 초대하고 싶으신 경우 설정에서 초대하기를 클릭해 주세요.');
          this.closePopup();
          this.$router.push('/my/myConference');
        });
      }
    }
  }
}

</script>

<style scoped>

</style>